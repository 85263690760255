<template>
	<b-card class="alarms-list" @click.stop.prevent="toggle()">
		<template #header>
			<span id="responsive-label">Log de Alarmes</span>
			<span class="float-right">
				<clock-icon/> <span>{{ date }}</span>
			</span>
			<ChevronRightIcon v-show="isOpen" class="responsive" @click.stop.prevent="toggle()" color="black" />
			<ChevronDownIcon v-show="!isOpen" class="responsive" @click.stop.prevent="toggle()" color="black" />
		</template>
		<transition name="fadeHeight">
			<b-card-text class="text-left" :class="mobileView ?'p-3':''" v-show="!isOpen" >
				<ul v-if="alarms.length" class="mb-0">
					<li v-for="(alarm, idx) in alarms" :key="idx">
						<div class="alarm mb-2" style="align-items: center;">
							<div
								class="state text-center"
								style="height: 48px; color: black;"
								:class="{
									'bg-danger': alarm.nivel === 'HH' || alarm.nivel === 'LL',
									'bg-warning': alarm.nivel === 'L' || alarm.nivel === 'H',
									'bg-danger-off': (alarm.nivel === 'HH' || alarm.nivel === 'LL') && alarm.state !==  alarm.estado_interesse,
									'bg-warning-off': (alarm.nivel === 'L' || alarm.nivel === 'H') && alarm.state !== alarm.estado_interesse
								}"
							>
								{{ alarm.count ? alarm.count : "" }}
							</div>
							<div class="details text-ellipsis ml-2" :title="`${ alarm.descricao }`">
								<span>{{ alarm.data }}</span><br/>
								<span> {{ alarm.tag }} </span>
							</div>
							<div>
								<button
								:disabled="alarm.state === alarm.estado_interesse"
									class="btn recognize"
									:class="{
										'recognize-off': alarm.state === alarm.estado_interesse,
										'recognize-on': alarm.state !== alarm.estado_interesse,
									}"
									@click="reconhecerAlarme(alarm)"
								>
									Reconhecer
								</button>
							</div>
						</div>
					</li>
				</ul>

				<div v-if="!alarms.length" class="alert alert-success reveal-animation mb-0">
					Nenhum alarme ativo no momento.
				</div>
			</b-card-text>
		</transition>
	</b-card>
</template>

<script>
	import dayjs from "dayjs";

	import { AlarmesService } from "@/services/alarmes";
	import { AuthService } from "@/services/auth";

	export default {
		props: {
			idMaquina: {
				type: Number,
				required: false
			},
			nomeMaquina: {
				type: String,
				required: false
			}
		},

		data () {
			return {
				alarms: [],
				date: "Carregando...",
				alarmsListInterval: null,
				alarmesService: new AlarmesService(),
				authService: new AuthService(),
				isOpen: false,
				mobileView: false,
				dictSensores: {
					PT_D: "/Sensoriamento/Sensores/Direita/PT_Cinza.png",
					LT_D: "/Sensoriamento/Sensores/Direita/LT_Cinza.png",
					PT_E: "/Sensoriamento/Sensores/Esquerda/PT_Cinza.png",
					LT_E: "/Sensoriamento/Sensores/Esquerda/LT_Cinza.png",

					HH_LT_D: "/Sensoriamento/Sensores/Direita/LT_Verm_Escuro.png",
					LL_LT_D: "/Sensoriamento/Sensores/Direita/LT_Verm_Escuro.png",
					HH_LT_D_OFF: "/Sensoriamento/Sensores/Direita/LT_Verm_Claro.png",
					LL_LT_D_OFF: "/Sensoriamento/Sensores/Direita/LT_Verm_Claro.png",
					H_LT_D: "/Sensoriamento/Sensores/Direita/LT_Amarelo_Escuro.png",
					L_LT_D: "/Sensoriamento/Sensores/Direita/LT_Amarelo_Escuro.png",
					H_LT_D_OFF: "/Sensoriamento/Sensores/Direita/LT_Amarelo_Claro.png",
					L_LT_D_OFF: "/Sensoriamento/Sensores/Direita/LT_Amarelo_Claro.png",

					HH_LT_E: "/Sensoriamento/Sensores/Esquerda/LT_Verm_Escuro.png",
					LL_LT_E: "/Sensoriamento/Sensores/Esquerda/LT_Verm_Escuro.png",
					HH_LT_E_OFF: "/Sensoriamento/Sensores/Esquerda/LT_Verm_Claro.png",
					LL_LT_E_OFF: "/Sensoriamento/Sensores/Esquerda/LT_Verm_Claro.png",
					H_LT_E: "/Sensoriamento/Sensores/Esquerda/LT_Amarelo_Escuro.png",
					L_LT_E: "/Sensoriamento/Sensores/Esquerda/LT_Amarelo_Escuro.png",
					H_LT_E_OFF: "/Sensoriamento/Sensores/Esquerda/LT_Amarelo_Claro.png",
					L_LT_E_OFF: "/Sensoriamento/Sensores/Esquerda/LT_Amarelo_Claro.png",

					HH_PT_D: "/Sensoriamento/Sensores/Direita/PT_Verm_Escuro.png",
					LL_PT_D: "/Sensoriamento/Sensores/Direita/PT_Verm_Escuro.png",
					HH_PT_D_OFF: "/Sensoriamento/Sensores/Direita/PT_Verm_Claro.png",
					LL_PT_D_OFF: "/Sensoriamento/Sensores/Direita/PT_Verm_Claro.png",
					H_PT_D: "/Sensoriamento/Sensores/Direita/PT_Amarelo_Escuro.png",
					L_PT_D: "/Sensoriamento/Sensores/Direita/PT_Amarelo_Escuro.png",
					H_PT_D_OFF: "/Sensoriamento/Sensores/Direita/PT_Amarelo_Claro.png",
					L_PT_D_OFF: "/Sensoriamento/Sensores/Direita/PT_Amarelo_Claro.png",

					HH_PT_E: "/Sensoriamento/Sensores/Esquerda/PT_Verm_Escuro.png",
					LL_PT_E: "/Sensoriamento/Sensores/Esquerda/PT_Verm_Escuro.png",
					HH_PT_E_OFF: "/Sensoriamento/Sensores/Esquerda/PT_Verm_Claro.png",
					LL_PT_E_OFF: "/Sensoriamento/Sensores/Esquerda/PT_Verm_Claro.png",
					H_PT_E: "/Sensoriamento/Sensores/Esquerda/PT_Amarelo_Escuro.png",
					L_PT_E: "/Sensoriamento/Sensores/Esquerda/PT_Amarelo_Escuro.png",
					H_PT_E_OFF: "/Sensoriamento/Sensores/Esquerda/PT_Amarelo_Claro.png",
					L_PT_E_OFF: "/Sensoriamento/Sensores/Esquerda/PT_Amarelo_Claro.png",

					NA: "/Sensoriamento/Sensores/NA/Cinza.svg",
					LL_NA: "/Sensoriamento/Sensores/NA/Vermelho.svg",
					LL_NA_OFF: "/Sensoriamento/Sensores/NA/Vermelho_Claro.svg",
					HH_NA: "/Sensoriamento/Sensores/NA/Vermelho.svg",
					HH_NA_OFF: "/Sensoriamento/Sensores/NA/Vermelho_Claro.svg",
					L_NA: "/Sensoriamento/Sensores/NA/Amarelo.svg",
					L_NA_OFF: "/Sensoriamento/Sensores/NA/Amarelo_Claro.svg",
					H_NA: "/Sensoriamento/Sensores/NA/Amarelo.svg",
					H_NA_OFF: "/Sensoriamento/Sensores/NA/Amarelo_Claro.svg"
				},
				atualizandoAlarmes: false
			};
		},

		mounted () {
			this.date = dayjs().format("DD/MM/YYYY HH:mm:ss");
			this.ajustaHoras();
		},

		beforeDestroy () {
			clearInterval(this.alarmsListInterval);
		},

		methods: {
			ajustaHoras () {
				this.date = dayjs().format("DD/MM/YYYY HH:mm:ss");
			},

			obtemURLSensor (tipoSensor, lado = "", nivel = "", estado = "") {
				const chaves = [nivel, tipoSensor, lado].filter(c => !!c);
				if (estado === "OFF")
					chaves.push("OFF");

				return this.dictSensores[chaves.join("_")];
			},

			countAlarms (alarmesOrdenados) {
				const alarmesContados = {};
				const idsAlarmes = [];

				for (const alarm of alarmesOrdenados) {
					if (alarm.state === alarm.estado_interesse) {
						alarmesContados[alarm.id + "ON"] = {
							id: alarm.id,
							count: 0,
							ids_historiador: [alarm.id_historiador],
							data: alarm.data,
							classe: alarm.classe,
							descricao: alarm.descricao,
							maquina: alarm.maquina,
							nivel: alarm.nivel,
							nomeSensor: alarm.nomeSensor,
							state: alarm.state,
							estado_interesse: alarm.estado_interesse,
							tag: alarm.tag
						};
					} else if (!idsAlarmes.includes(alarm.id) && alarm.state !== alarm.estado_interesse) {
						idsAlarmes.push(alarm.id);
						alarmesContados[alarm.id] = {
							id: alarm.id,
							count: 1,
							ids_historiador: [alarm.id_historiador, alarm.id_historiador2],
							data: alarm.data,
							classe: alarm.classe,
							descricao: alarm.descricao,
							maquina: alarm.maquina,
							nivel: alarm.nivel,
							nomeSensor: alarm.nomeSensor,
							state: alarm.state,
							estado_interesse: alarm.estado_interesse,
							tag: alarm.tag
						};
					} else if (alarm.state !== alarm.estado_interesse) {
						alarmesContados[alarm.id].count += 1;
						alarmesContados[alarm.id].ids_historiador.push(alarm.id_historiador, alarm.id_historiador2);
						alarmesContados[alarm.id].data = alarm.data;
					}
				}

				const reducedArray = [];
				for (const count in alarmesContados)
					reducedArray.push(alarmesContados[count]);

				reducedArray.sort((a, b) => dayjs(b.data) - dayjs(a.data));
				return reducedArray;
			},

			async updateAlarmsList () {
				try {
					// Para evitar a chamada de uma atualização antes que uma anterior termine
					if (this.atualizandoAlarmes)
						return;

					this.atualizandoAlarmes = true;

					if (this.idMaquina !== null && this.alarmsListInterval === null) {
						this.alarmsListInterval = setInterval(this.updateAlarmsList.bind(this), 15 * 1000);
					}

					const maquinaPesquisa = {
						id: this.idMaquina,
						nome: this.nomeMaquina || ""
					};

					const informacoes = await this.alarmesService.listaAlarmesAtivos(maquinaPesquisa, true);
					this.alarms = this.countAlarms(informacoes.alarms || []);

					for (const alarm of this.alarms)
						alarm.data = dayjs(alarm.data).format("DD/MM/YYYY HH:mm:ss");

					this.date = dayjs().format("DD/MM/YYYY HH:mm:ss");

					let tipoSensor, lado, nivel, estado, estado_interesse, estado_parametro;
					for (let i = 0; i < informacoes.alarmesSensoresTela.length; i++) {
						tipoSensor = informacoes.alarmesSensoresTela[i].tipo;
						lado = informacoes.alarmesSensoresTela[i].lado;
						nivel = informacoes.alarmesSensoresTela[i].nivel;
						estado = informacoes.alarmesSensoresTela[i].state;
						estado_interesse = informacoes.alarmesSensoresTela[i].estado_interesse;
						estado_parametro = "";

						if (estado === "ON" || estado === "OFF")
							estado_parametro = estado === estado_interesse ? "ON" : "OFF";

						informacoes.alarmesSensoresTela[i].img = this.obtemURLSensor(tipoSensor, lado, nivel, estado_parametro);
					}

					this.ajustaHoras();
					this.$emit("alarmes_log", informacoes.alarmesSensoresTela, informacoes.linkArquitetura);
					this.atualizandoAlarmes = false;
				} catch (error) {
					this.atualizandoAlarmes = false;
					console.error(error);
					this.$swal({
						title: "Falha ao buscar alarmes!",
						type: "error",
						html: `<p>${(error.response && error.response.data.error) || error.message}</p>`,
						confirmButtonText: "Fechar",
						confirmButtonColor: "#6c757d"
					});

					if (error.response?.status === 403)
						await this.authService.requireLogin();
				}
			},

			handleView () {
				this.mobileView = window.innerWidth <= 850;
				if (window.innerWidth > 850)
					this.isOpen = false;
				else
					this.isOpen = true;
			},

			toggle () {
				if (this.mobileView)
					this.isOpen = !this.isOpen;
				else
					this.isOpen = false;
			},

			async reconhecerAlarme (alarm) {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						await this.alarmesService.reconhecerAlarme(alarm);
						this.updateAlarmsList();
						return {
							title: "Sucesso!",
							body: "Alarme reconhecido com sucesso.",
							config
						};
					} catch (error) {
						console.error("Erro ao reconhecer alarme:", error);
						throw {
							title: "Falha!",
							body: "Erro ao reconhecer alarme.",
							config
						};
					}
				});
			}
		},

		created () {
			this.handleView();
			window.addEventListener("resize", this.handleView);
		},

		watch: {
			idMaquina: "updateAlarmsList"
		}
	};
</script>

<style lang="scss" scoped>
	.card-header span {
		line-height: 27px;
		vertical-align: middle;
	}

	.card-body {
		height: 100%;
		overflow-y: auto;
	}

	.recognize {
		align-content: center;
		color: #fff;
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
		align-items: center;
		text-align: center;
	}

	.recognize-off {
		background-color: #aca8a8;
	}
	.recognize-on {
		background-color: #FAAF40;
	}

	.recognize:hover:enabled {
		color: #fff;
		background-color: #59b0d3;
		border-color: #59b0d3;
	}
	.recognize:hover:disabled {
		cursor: inherit;
	}

	ul {
		list-style: none;
		padding: 0;

		li:last-child .alarm {
			margin-bottom: 0 !important;
		}
	}

	.alarm {
		width: 100%;
		display: flex;
		flex-direction: row;

		.state {
			border-radius: 5px;
			width: 48px;
			line-height: 48px;
			background: var(--secondary);

			&:not(.bg-warning) {
				color: white;
			}

			&.on {
				background: var(--danger);
			}
		}

		.details {
			width: calc(100% - 40px);
		}
	}

	.responsive {
		display: none;
	}

	.fadeHeight-enter-active,
	.fadeHeight-leave-active {
		transition: all 0.5s;
		max-height: 700px;
	}

	.fadeHeight-enter,
	.fadeHeight-leave-to {
		opacity: 0;
		max-height: 0px;
	}

	@media (max-width: 850px) {
		.alarms-list {
			position: relative !important;
			width: calc(100% - 2rem) !important;
			margin-left: auto;
			margin-right: auto;
			margin-top: calc(66px + 1rem);
			margin-bottom: 1rem;
		}

		.card-body {
			padding: 0px;
		}

		.show-data {
			display: none !important;
		}

		.responsive {
			display: inline;
			float: left;
		}

		#responsive-label {
			padding-left: 10px;
		}
	}

	.bg-warning {
		background-color: #FAAF40 !important;
	}

	.bg-danger {
		background-color: #EC1C24 !important;
	}

	.bg-warning-off {
		background-color: #facf8e !important;
	}

	.bg-danger-off {
		background-color: #eb8286 !important;
	}
</style>
